import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';

import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import * as backendModule from "./modules/backendModule";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

const store = createStore(allReducers, composeWithDevTools());

backendModule.setAxiosDataHandler();
backendModule.setStore(store);

function entry() {
    const rootElem = createRoot(document.querySelector("#root"));
    rootElem.render(<Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>);
};

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        // Custom behavior when there's a new service worker version
        alert('New version available! Please refresh.');
    },
});

entry();