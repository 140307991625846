import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectTrackingProfile } from "../../actions/trackingProfilesActions";

import Spinner from "../customComponents/Spinner";

const UserAccount = (props) => {
    const curNavigate = useNavigate();

    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const currentTrackingProfiles = useSelector(state => state?.trackingProfiles ?? {});

    const [selectedGroup, setSelectedGroup] = React.useState();
    const [searchGroup, setSearchGroup] = React.useState();
    const [selectedProfiles, setSelectedProfiles] = React.useState();
    const [dropdownVisible, setdropdownVisible] = React.useState(false);
    const [submenu, setSubmenu] = React.useState(false);
    const [trackingProfiles, setTrackingProfiles] = React.useState();

    const dropdownRef = React.useRef();
    const accountBtn = React.useRef();
    const accountArrow = React.useRef();
    const maxHeightValue = React.useRef();
    const searchRef = React.useRef();

    const mainDispatch = useDispatch();

    const getIntegrationImage = (integrationID) => {
        let curIntegration = getIntegration(integrationID);

        switch (curIntegration?.type) {
            case "scale-track": return "/images/logo.svg";
            case "facebook": return "/images/integrations/integration_header_facebook.svg";
            case "tiktok": return "/images/integrations/integration_header_tiktok.svg";
            case "mgid": return "/images/integrations/integration_header_mgid.svg";
            case "midas": return "/images/integrations/integration_midas.svg";
            case "adnow": return "/images/integrations/integration_adnow.svg";
            case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
            case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";
            default: return "#";
        };
    };
    const getIntegration = integrationID => {
        let curProfile = currentTrackingProfiles?.profiles?.find(p => p.ID === integrationID);
        
        return curProfile ?? null;
    };
    const formatIntegrationName = name => {
        if (name) {
            if (name.startsWith("(Personal)")) {
                name = name.split("(Personal)").pop();
                return <>
                    <span style={{color: "rgb(69, 168, 255)"}} title="Personal account">(P)</span>
                    {name}
                </>
            };
        };
        return name;
    };
    const checkIfGenericProfile = id => {
        switch (id) {
            case "-1":
            case "-2":
            case "-3":
            case "md":
            case "mg":
            case "an":
                return true;
            default:
                return false;
        };
    };
    const searchProfiles = () => {
        let tmp = [];
        for (let profile of selectedProfiles.profiles) {
            if (profile.ID.toLowerCase().includes(searchRef.current.value.toLowerCase()) || profile.name.toLowerCase().includes(searchRef.current.value.toLowerCase())) {
                tmp.push(profile)
            }
        }
        setSearchGroup({ type: selectedProfiles.type, profiles: tmp });
    };

    React.useEffect(() => {
        const closeDropdown = () => {
            if (dropdownVisible) setdropdownVisible(false);
        };

        window.addEventListener("click", closeDropdown);
        return () => {
            try {
                window.removeEventListener("click", closeDropdown);
            } catch { };
        };
    }, [dropdownVisible]);

    React.useEffect(() => {
        if (!dropdownRef?.current) return;

        maxHeightValue.current = currentTrackingProfiles.profiles.length * 60;
        if (currentTrackingProfiles.selectedProfile !== -1) {
            maxHeightValue.current += 61;
            if (submenu) setSubmenu(false);
        };
    }, [dropdownRef.current, currentTrackingProfiles]);

    React.useEffect(() => {
        setTrackingProfiles();
        if (!currentTrackingProfiles) return;
        let profilestmp = {};
        currentTrackingProfiles.profiles.map(item => {
            if (!profilestmp[item.ID.slice(0, 2)]) {
                profilestmp[item.ID.slice(0, 2)] = {
                    type: item.type,
                    profiles: [
                        {
                            ID: item.ID,
                            name: item.name,
                            banned: !!item.banned
                        }
                    ]
                }
            } else {
                profilestmp[item.ID.slice(0, 2)].profiles.push({
                    ID: item.ID,
                    name: item.name,
                    banned: !!item.banned
                })
            }
            setTrackingProfiles(profilestmp)
        });
    }, [currentTrackingProfiles]);

    React.useEffect(() => {
        setSearchGroup(selectedProfiles);
    }, [selectedProfiles]);


    return <div className="component__userAccount" ref={accountBtn} onClick={e => {
        e.stopPropagation();
        setdropdownVisible(pastState => !pastState)
    }} style={{
        display: userDataSelector?.UserInfo?.Flags?.isSocialMediaManager ? "none" : null
    }}>
        <div className="component__userAccount__user" style={{...(props?.style ?? {})}}>
            <p className="component__userAccount__user__bottom">
                {currentTrackingProfiles?.selectedProfile === undefined ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : <>
                    {currentTrackingProfiles.selectedProfile === null ? <p>Error!</p> : <>
                        <img src={getIntegrationImage(currentTrackingProfiles.selectedProfile)} />
                        <p>{formatIntegrationName(getIntegration(currentTrackingProfiles?.selectedProfile)?.name ?? "?")}</p>
                    </>}
                </>}
            </p>
        </div>
        <div className={`component__userAccount__dropdown ${dropdownVisible ? "component__userAccount__dropdown--active" : ""} ${submenu === true ? "component__userAccount__dropdown--custom" : ""}`} ref={dropdownRef} style={{
            maxHeight: dropdownVisible ? `${maxHeightValue.current > 600 ? 600 : maxHeightValue.current}px` : 0
        }}>
            <div className="component__userAccount__dropdown__left" style={{
                width: submenu ? '0%' : '100%',
                transition: "all 0.3s ease"
            }} >
                {
                    trackingProfiles && Object.keys(trackingProfiles).map(profile => {
                        return <p
                            className={selectedGroup === profile ? "component__userAccount__dropdown__active" : null}
                            onClick={(e) => {
                                if (checkIfGenericProfile(profile)) {
                                    mainDispatch(selectTrackingProfile(trackingProfiles[profile].profiles[0].ID));
                                    setSelectedProfiles(null);
                                    setSubmenu(false);
                                } else {
                                    e.stopPropagation();
                                    setSelectedProfiles(trackingProfiles[profile]);
                                    setSubmenu(true)
                                }
                                setSelectedGroup(profile);
                            }}

                        >
                            <img src={getIntegrationImage(trackingProfiles[profile].profiles[0].ID)} />
                            {checkIfGenericProfile(profile) ? trackingProfiles[profile].profiles[0].name : trackingProfiles[profile].type}
                        </p>
                    })
                }
            </div>
            <div className="component__userAccount__dropdown__right">
                <input onClick={e => {
                    e.stopPropagation();
                }} placeholder="Search" ref={searchRef} onChange={() => { searchProfiles() }} className="component__userAccount__dropdown__right__search" />
                <p style={{paddingTop: "12px", marginLeft: "5px"}} onClick={(e) => {
                    e.stopPropagation();
                    setSubmenu(false);
                }}>Go back</p>
                {searchGroup && searchGroup.profiles.map(profile => {
                    let profileID = profile.ID.split('-')[1];
                    checkIfGenericProfile(profile) ? profileID = '' : profileID = profileID;
                    return <p
                        className={currentTrackingProfiles.selectedProfile === profile.ID ? "component__userAccount__dropdown__active" : null}
                        onClick={() => mainDispatch(selectTrackingProfile(profile.ID))}
                        style={{
                            color: profile.banned ? "rgb(238, 102, 102)" : null
                        }}
                    >
                        <span style={{ left: "10px" }} >{profileID}</span>
                        {profile.name}
                    </p>
                })}
            </div>
        </div>
    </div>
};

export default UserAccount;