import React from "react";
import "./scss/globals.scss";
import { Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import animateModule from "./modules/animateModule";
import * as backendModule from "./modules/backendModule";
import * as siteFunctionsActions from "./actions/siteFunctionsActions";
import * as userDataActions from "./actions/userDataActions";
import * as timestmapActions from "./actions/timestampActions";
import * as typesActions from "./actions/typesActions";
import * as trackingProfilesActions from "./actions/trackingProfilesActions";
import useDefer from "./modules/hooks/useDefer";

import Header from "./components/Header";
import Spinner from "./components/customComponents/Spinner";

import Login from "./routes/Login";
import ContentWrapper from "./components/ContentWrapper";

import LandingPage from "./routes/LandingPage";
import LandingHeader from "./components/LandingHeader";
import LandingFooter from "./components/LandingFooter";

import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsOfService from "./routes/TermsOfService";
import DataDeletionPolicy from "./routes/DataDeletionPolicy";

import User_AccountManagement from "./routes/user/AccountManagement";
import Reports_MarketingOffers from "./routes/user/reports/MarketingOffers";


let loginTimeout = null;
const App = () => {
  const userDataSelector = useSelector(state => state?.userData ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const siteTrackingProfilesTimestamp = useSelector(state => state?.siteFunctions?.siteTrackingProfilesTimestamp ?? Date.now());
  const siteFunctionsSelector = useSelector(state => state?.siteFunctions?.headerRefreshHandlers ?? []);
  const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
  const siteFunctionsStickyHeaderSelector = useSelector(state => state?.siteFunctions?.stickyHeader ?? true);
  const selectedTeamSelector = useSelector(state => state?.trackingProfiles?.selectedTeam ?? null);
  const selectedProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [notifications, setNotifications] = React.useState();
 
  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();

  const columnUpdateDefer = useDefer();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  const getTrackingProfiles = () => {
    if (!selectedTeamSelector) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/common/getTrackingProfiles`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(trackingProfilesActions.updateTrackingProfiles(res.data.data));
        
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
          data: {
              Integration: `initial_selected_profile`
          },
          ...backendModule.axiosConfig
        }).then(res2 => {
          if (res2.data.status === "ok" && res2.data.data?.[0] && res2.data.data?.[1]) {
            if (res2.data.data[0] === selectedTeamSelector) return mainDispatch(trackingProfilesActions.selectTrackingProfile(res2.data.data[1] ?? null));
          };
          return mainDispatch(trackingProfilesActions.selectTrackingProfile(res.data.data[0]?.ID ?? null));
        }).catch(() => {
          return mainDispatch(trackingProfilesActions.selectTrackingProfile(res.data.data[0]?.ID ?? null));
        });

      } else {
        mainDispatch(trackingProfilesActions.selectTrackingProfile(null));
      };
    }).catch(() => {
      mainDispatch(trackingProfilesActions.selectTrackingProfile(null));
    });
  };

  const getTrackingTeams = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/common/getTrackingTeams`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(trackingProfilesActions.updateTrackingTeams(res.data.data));
        mainDispatch(trackingProfilesActions.selectTrackingTeam(res.data.data[0]?.ID ?? null));

        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
          data: {
              Integration: `initial_selected_team`
          },
          ...backendModule.axiosConfig
        }).then(res2 => {
          if (res2.data.status === "ok" && res2.data.data?.[0]) return mainDispatch(trackingProfilesActions.selectTrackingTeam(res2.data.data[0] ?? null));
          return mainDispatch(trackingProfilesActions.selectTrackingTeam(res.data.data[0]?.ID ?? null));
        }).catch(() => {
          return mainDispatch(trackingProfilesActions.selectTrackingTeam(res.data.data[0]?.ID ?? null));
        });

      } else {
        mainDispatch(trackingProfilesActions.selectTrackingTeam(null));
      };
    }).catch(() => {
      mainDispatch(trackingProfilesActions.selectTrackingTeam(null));
    });
  };

  const getTypes = () => {
    if (Object.keys(backendModule.getStore().getState().types).length > 0) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/common/getTypes`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(typesActions.setTypes(res.data.data));
      };
    }).catch(() => null);
  };

  const getNotifications = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/notifications/getAllNotifications`,
      data: {
        limit: 200
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setNotifications(res.data)
    }).catch(() => {
      return null;
    })
  }

  const getTheme = async () => {
    let curTheme = await axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/vars/getVar`,
      data: {
        Name: "user_theme"
      },
      ...backendModule.axiosConfig
    }).then(res => res.data).catch(() => backendModule.genericError);

    if (curTheme.status === "ok") {
      mainDispatch(siteFunctionsActions.changeSiteTheme(curTheme.data));
    };
  };

  const checkLogin = () => {
    getNotifications();
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getTypes();

        if (!userDataSelector?.isLoggedIn) {
          mainDispatch(userDataActions.setUserData(true, res.data.data));
          if (window.location.toString().endsWith("/login")) animateNavigate("/");
        };

        loginTimeout = setTimeout(checkLogin, 60000);
      } else {
        if (
          !window.location.toString().endsWith("/#/privacy") &&
          !window.location.hash.startsWith("#/share-campaign/") &&
          !window.location.hash.startsWith("#/live-share")
        ) animateNavigate("/");
        mainDispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      mainDispatch(userDataActions.setUserData(null, null));
      setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 2000);

      loginTimeout = setTimeout(checkLogin, 2000);
    });
  };

  React.useEffect(() => {
    checkLogin();

    return () => clearTimeout(loginTimeout);
  }, [timestampSelector]);

  React.useEffect(() => {
    if (!userDataSelector?.isLoggedIn) return;

    getTrackingTeams();
    getTheme();
  }, [siteTrackingProfilesTimestamp, userDataSelector?.isLoggedIn]);

  React.useEffect(() => {
    if (!selectedTeamSelector) return;

    let handler = axios.interceptors.request.use(config => {
      if (!config.params) config.params = {};
      config.params["teamID"] = selectedTeamSelector;
      return config;
    });

    getTrackingProfiles();

    for (let item of siteFunctionsSelector) item();

    return () => axios.interceptors.request.eject(handler);
  }, [selectedTeamSelector]);

  React.useEffect(() => {
    columnUpdateDefer(() => {
      if (selectedTeamSelector) {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
          data: {
              Integration: `initial_selected_team`,
              Columns: [selectedTeamSelector]
          },
          ...backendModule.axiosConfig
        }).then(() => null).catch(() => null);
      };

      if (selectedProfileSelector && selectedTeamSelector) {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
          data: {
              Integration: `initial_selected_profile`,
              Columns: [selectedTeamSelector, selectedProfileSelector]
          },
          ...backendModule.axiosConfig
        }).then(() => null).catch(() => null);
      };
    }, 1000);
  }, [selectedTeamSelector, selectedProfileSelector]);

  React.useEffect(() => {
    let handler = (e) => {
      if (
        e?.oldURL.endsWith("/login") ||
        e?.newURL.endsWith("/login")
      ) setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 0);
    };

    window.addEventListener("hashchange", handler);

    return () => window.removeEventListener("hashchange", handler);
  }, []);

  React.useEffect(() => {
    if (!userDataSelector?.isLoggedIn) {
      mainDispatch(trackingProfilesActions.resetTrackingData())
    };
  }, [timestampSelector, userDataSelector?.isLoggedIn]);

  React.useEffect(() => {
    if (themeSelector) {
      switch (themeSelector) {
        case "light":
          document.body.classList.remove("dark");
          document.body.classList.add("light");
          break;
        case "dark":
          document.body.classList.remove("light");
          document.body.classList.add("dark");
          break;
        default: break;
      };
    };
  }, [themeSelector]);

  return userDataSelector.isLoggedIn === null ? <div className="root__init">
    <Spinner color="#FCA311" />
  </div> : (userDataSelector.isLoggedIn ? <React.Fragment key="logged-in-fragment">
    <ContentWrapper key="wrapper-loggedIn" style={{
      display: "grid",
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr",
      paddingTop: '74px'
    }}>
      <Header />
      <div className={`root__content ${siteFunctionsStickyHeaderSelector ? "" : "root__content--noSticky"}`}>
        <Routes>
          <Route path="/" element={<Reports_MarketingOffers />} />
          {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isManager) && <Route path="/account-management" element={<User_AccountManagement />} />}
        </Routes>
      </div>
    </ContentWrapper>
  </React.Fragment> : <>
    <ContentWrapper style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }}>
      <LandingHeader />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/data-deletion" element={<DataDeletionPolicy />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
      <LandingFooter />
    </ContentWrapper>
  </>)
};

export default App;