import React from "react";
import "./index.scss";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Icon_accountManagement } from "./images/header_accountManagement.svg";
import { ReactComponent as Icon_reports } from "./images/header_reports.svg"
import { ReactComponent as Icon_logout } from "./images/header_logout.svg"

import animateModule from "../../modules/animateModule";
import * as backendModule from "../../modules/backendModule";
import * as timestampActions from "../../actions/timestampActions";

const Header = () => {
    const [hamburgerOpen, setHamburgerOpen] = React.useState(false);

    const curLocation = useLocation();
    const curNavigate = useNavigate();
    const curDispatch = useDispatch();

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const getHeaderName = () => {
        if (curLocation.pathname == "/") return "Reports";
        if (curLocation.pathname.startsWith("/account-management")) return "Account management";
        return "?";
    };

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".root__content"));

    const switchSite = (site) => {
        setHamburgerOpen(false);
        animateNavigate(`/${site}`);
    };

    const logOut = () => {
        setHamburgerOpen(false);
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            curDispatch(timestampActions.updateTimestamp());
            animateNavigate("/");
        });
    };

    return <div className="component__header">
        <div className="component__header__hamburger" onClick={() => setHamburgerOpen(ho => !ho)}>
            <img src="/images/icon_hamburger.svg" />
        </div>
        <div className="component__header__name">
            {getHeaderName()}
        </div>

        <div className="component__header__content" style={{
            maxHeight: hamburgerOpen ? "100vh" : 0,
            padding: hamburgerOpen ? "24px" : "0 24px"
        }} onClick={e => e.stopPropagation()}>
            <div className="component__header__content__head">
                <div className="component__header__content__head__left">
                    <img src="/images/logo.svg" />
                    <p>Scale Track</p>
                </div>
                <div className="component__header__content__head__right" onClick={() => setHamburgerOpen(ho => !ho)}>
                    <img src="/images/icon_hamburgerOpen.svg" />
                </div>
            </div>

            <div className="component__header__content__buttons">
                <div className={`component__header__content__buttons__btn ${getHeaderName() === "Reports" ? "component__header__content__buttons__btn--active" : ""}`} onClick={() => switchSite("")}>
                    <Icon_reports />
                    <p>Reports</p>
                </div>
                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isManager) && <div className={`component__header__content__buttons__btn ${getHeaderName("") === "Account management" ? "component__header__content__buttons__btn--active" : ""}`} onClick={() => switchSite("account-management")}>
                    <Icon_accountManagement />
                    <p>Account management</p>
                </div>}
                <div className="component__header__content__buttons__btn" onClick={() => logOut()}>
                    <Icon_logout />
                    <p>Logout</p>
                </div>
            </div>
        </div>
    </div>
};

export default Header;