import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import * as miscModule from "../../../modules/miscModule";
import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../components/customComponents/Spinner";

import { FilteredCustomTable } from "../../../components/customComponents/Table";

const User_AccountManagement = () => {
    const [data, setData] = React.useState();
    const [search, setSearch] = React.useState();
    const [selectedAccount, setSelectedAccount] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    React.useEffect(() => {
        if (!selectedDate) return;
        if (!selectedAccount) return;

        setData();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/getAccounts`,
            data: {
                Date: moment(selectedDate.start).add(12, "hours").toDate().getTime(),
                DateEnd: moment(selectedDate.end).add(-12, "hours").toDate().getTime(),
                trackFilters: [
                    { name: "createdAt", op: "pdgeq", value: moment(selectedDate.start).startOf("day").toDate().getTime() },
                    { name: "createdAt", op: "pdleq", value: moment(selectedDate.end).endOf("day").toDate().getTime() }
                ],
                Type: selectedAccount,
                utcOffset: (new Date()).getTimezoneOffset()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, [selectedDate, selectedAccount]);

    return <div className="route__user__accountManagement">
        <div className="route__user__accountManagement__filters">
            <FilterBySearch onChange={e => setSearch(e)} />

            <AdvancedDropdown
                headline="Integration"
                data={[
                    { key: "facebook", name: "Facebook", value: "facebook", image: miscModule.getTrackingProfileImage("facebook") },
                    { key: "mgid", name: "Mgid", value: "mgid", image: miscModule.getTrackingProfileImage("mgid") },
                    { key: "midas", name: "Midas", value: "midas", image: miscModule.getTrackingProfileImage("midas") },
                    { key: "adnow", name: "AdNow", value: "adnow", image: miscModule.getTrackingProfileImage("adnow") },
                ]}
                onChange={e => e?.value && setSelectedAccount(e?.value)}
            />

            <FilterByDate
                style={{gridColumn: "1 / span 2"}}
                disableAll={true}
                disable24h={true}
                defaultValue="today"
                onChange={e => setSelectedDate(e)}
            />
        </div>

        <div className="route__user__accountManagement__table">
            {selectedAccount && <FilteredCustomTable
                accent="#6C5DD3"
                theme={themeSelector}
                headers={selectedAccount === "facebook" ? ["Account", "Spent", "Spent API", "Approved", "Integration active", "Account active", "Rejected ads", "Missing ads", "Status"] : ["Account", "Spent", "Approved"]}
                customColumns={(new Array(selectedAccount === "facebook" ? 9 : 3)).fill("max-content")}
                style={{
                    columnGap: "40px"
                }}
                data={(()=>{
                    const yesColor = themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
                    const noColor = themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
                    if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                    if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "There was an error while fetching data", style: {color: noColor}}]];
                    let out = [];

                    let count = 0;
                    for (let user of data.data) {
                        for (let profile of user.Profiles) {
                            if (search) {
                                let s = search.toLowerCase();
                                if (
                                    !user.Username.toLowerCase().includes(s) &&
                                    !profile.Name.toLowerCase().includes(s)
                                ) continue;
                            };
                            count += 1;

                            out.push([
                                {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: <>

                                </>},
                                {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: `${Number(profile.Spent || 0).toFixed(2)} ${currencySignSelector}`},
                                (selectedAccount === "facebook" ? {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: `${Number(profile.SpentAPI || 0).toFixed(2)} ${currencySignSelector}`} : null),
                                
                                { keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: profile.isApproved === null ? "-" : <span style={{ color: profile.isApproved ? yesColor : noColor }}>{profile.isApproved ? "Yes" : "No"} - {Number(profile.ApprovedSpent).toFixed(2)} {currencySignSelector}</span> },

                                (selectedAccount === "facebook" ? {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: profile.integrationActive ? "Yes" : "No", style: {color: profile.integrationActive ? yesColor : noColor}} : null),
                                (selectedAccount === "facebook" ? {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: profile.AccountActive ? "Yes" : "No", style: {color: profile.AccountActive ? yesColor : noColor}} : null),

                                (selectedAccount === "facebook" ? {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_checkRejectedAds_inline integrationID={profile.IntegrationID} date={moment(selectedDate.start).add(12, "hours").toDate().getTime()} dateEnd={moment(selectedDate.end).add(-12, "hours").toDate().getTime()} />} : null),
                                (selectedAccount === "facebook" ? {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: <UserAccountManager_checkMissingAds_inline userID={user.ID} integrationID={profile.IntegrationID} date={moment(selectedDate.start).add(12, "hours").toDate().getTime()} dateEnd={moment(selectedDate.end).add(-12, "hours").toDate().getTime()} />} : null),

                                (selectedAccount === "facebook" ? {keyID: `${user.ID}-${profile.IntegrationID}`, type: "text", text: <div className="route__user__accountManagement__table__buttons">
                                    <div className="route__user__accountManagement__table__buttons__button route__user__accountManagement__table__buttons__button--gray">Missing ads</div>
                                    <div className="route__user__accountManagement__table__buttons__button route__user__accountManagement__table__buttons__button--red">Rejected ads</div>
                                </div>} : null)
                            ].filter(f => f))
                        };
                    };

                    if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                    return out;
                })()}
            />}
        </div>
    </div>
};

const UserAccountManager_checkRejectedAds_inline = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAllProfileAds`,
            data: {
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getRejectedPercentage = () => {
        if (!data) return <Spinner style={{width: "15px", height: "15px"}} color={themeSelector === "dark" ? "white" : "black"} />;
        if (data.status === "error") return "-";

        let total = 0;
        let rejected = 0;
        for (let item of data.data) {
            total += 1;
            if (![
                "ACTIVE", "PAUSED", "ADSET_PAUSED", "CAMPAIGN_PAUSED"
            ].includes(item.AdEffectiveStatus)) rejected += 1;
        };

        let finalPercent = 100 / total * rejected;
        if (isNaN(finalPercent)) finalPercent = 0;
        if (finalPercent === Number.NEGATIVE_INFINITY || finalPercent === Number.POSITIVE_INFINITY) finalPercent = 0;
        return `${finalPercent.toFixed(2)} %`
    };

    React.useEffect(() => {
        getData();
    }, []);

    return data ? <>
        {data.status === "ok" ? <>
            {getRejectedPercentage()}
        </> : <>
            -
        </>}
    </> : <>
        <Spinner style={{width: "15px", height: "15px"}} color={themeSelector === "dark" ? "white" : "black"} />
    </>
};

const UserAccountManager_checkMissingAds_inline = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/checkCampaignMissingAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return data ? <>
        {data.status === "ok" ? <>
            {data.data.length}
        </> : <>
            -
        </>}
    </> : <>
        <Spinner style={{width: "15px", height: "15px"}} color={themeSelector === "dark" ? "white" : "black"} />
    </>
};

const UserAccountManager_checkMissingAds = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/checkCampaignMissingAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Check missing ads</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {data ? <>
                {data.status === "ok" ? <>
                    {data.data.length === 0 ? <p>All ads were found!</p> : <>
                        <p>Ad Account ID: {props.integrationID}</p>
                        <p>The following ads were found in facebook profile, but not in track campaigns:</p>
                        <p>Make sure that:</p>
                        <p>1) The ad has the right Scale-Track campaign URL</p>
                        <p>2) The ad has correct ST URL paramaeters {`(stadid={{ad.id}}&stutm=facebook)`}</p>
                        <p>3) The ad has not yet received any significant traffic (missing visits)</p>
                        <br />
                        {data.data.map(d => <p>Ad id: {d}</p>)}
                    </>}
                </> : <>
                    <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching data!</p>
                </>}
            </> : <>
                <p>Checking ads...</p>
                <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />
            </>}
        </div>
    </div>
};

const UserAccountManager_checkRejectedAds = props => {
    const [data, setData] = React.useState();

    const wrapRef = React.useRef();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getAllProfileAds`,
            data: {
                UserID: props.userID,
                IntegrationID: props.integrationID,
                Date: props.date,
                DateEnd: props.dateEnd
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const prepareAds = () => {
        let out = {
            allAds: [],
            approvedAds: [],
            rejectedAds: []
        };
        if (!data) return out;
        if (data.status !== "ok") return out;

        for (let item of data.data) {
            out.allAds.push(item);
            if ([
                "ACTIVE", "PAUSED", "ADSET_PAUSED", "CAMPAIGN_PAUSED"
            ].includes(item.AdEffectiveStatus)) {
                out.approvedAds.push(item);
            } else {
                out.rejectedAds.push(item);
            };
        };

        return out;
    };

    const openStory = item => {
        if (!item.StoryID) return null;

        window.open(`https://facebook.com/${item.StoryID}`, "_blank");
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__user__accountManager__spentChange" onClick={() => onClose()}>
        <div className="route__user__accountManager__spentChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: `calc(100% - 86px)`}}>
            <div className="route__user__accountManager__spentChange__wrap__top">
                <div className="route__user__accountManager__spentChange__wrap__top__left">Rejected ads</div>

                <div className="route__user__accountManager__spentChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__spentChange__wrap__content">
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    headers={["All ads", "Approved ads", "Rejected ads", "Rejected status"]}
                    customColumns={(new Array(4)).fill("max-content")}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "There was an error while fetching data"}]];
                        let out = [];
                        let tmpData = prepareAds();
                        for (let i=0; i<=tmpData.allAds.length-1; i++) {
                            out.push([
                                {keyID: String(i), type: "text", text: tmpData.allAds[i].AdID, onClick: () => openStory(tmpData.allAds[i])},
                                {keyID: String(i), type: "text", text: tmpData.approvedAds[i] ? tmpData.approvedAds[i].AdID : "", onClick: () => openStory(tmpData.approvedAds[i])},
                                {keyID: String(i), type: "text", text: tmpData.rejectedAds[i] ? tmpData.rejectedAds[i].AdID : "", onClick: () => openStory(tmpData.rejectedAds[i])},
                                {keyID: String(i), type: "text", text: tmpData.rejectedAds[i] ? tmpData.rejectedAds[i].AdEffectiveStatus : ""}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

export default User_AccountManagement;